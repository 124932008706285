import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  CMSAPIDataItem,
  DocumentAttributes,
  TickerAttributes,
} from "../../types/cms";
import { getFormattedReportMetada } from "../../utils";
import ReportSection from "./ReportSection";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ReportDisclaimer, SunshineSupport } from "../../constants";
import ReportCard from "../ReportCard";

interface ReportViewerProps {
  report: DocumentAttributes & {
    associated_ticker?: { data: CMSAPIDataItem<TickerAttributes> };
  };
}

const ReportViewer: React.FC<ReportViewerProps> = ({ report }) => {
  const { title, reportingPeriod, jsondocument } =
    getFormattedReportMetada(report);
  // get reports under the same ticker that's not the current report
  const relatedDocuments = report?.associated_ticker?.data?.attributes
    ?.documents?.data?.length
    ? report.associated_ticker.data.attributes.documents.data.filter(
        (x) => x.attributes.report_id !== report.report_id,
      )
    : [];
  return (
    <AnimatePresence>
      <motion.div
        key="report-viewer"
        className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
      border-t-4 bg-white border-white border-t-bam-alternative-blue"
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 50,
          duration: 0.4,
        }}
      >
        <div className="my-3 pl-2 pr-8 sm:pl-4 sm:pr-12 pt-3 pb-2">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {title}
          </h1>
          <div className="mt-3 md:mt-4 xl:mt-5 text-sm xs:text-base text-gray-800">
            {reportingPeriod}
          </div>
          <div className="text-bam-blue mt-2 md:mt-3 xl:mt-4">
            <a
              href={`mailto:${SunshineSupport}`}
              className="flex gap-x-1 font-bebas-neue tracking-wide text-lg"
            >
              <div>
                <span className="sr-only">Email</span>
                <EnvelopeIcon className="w-6 h-6 mt-px" />
              </div>
              Contact
            </a>
          </div>
        </div>
        <div className="mt-1 xl:text-lg">
          {!!jsondocument?.overview?.content?.length && (
            <ReportSection type="text" section={jsondocument?.overview} />
          )}
          {!!jsondocument?.expert_network_questions?.content?.length && (
            <ReportSection
              type="list"
              section={jsondocument?.expert_network_questions}
            />
          )}
          {!!jsondocument?.transcript_questions?.content?.length && (
            <ReportSection
              type="list"
              section={jsondocument?.transcript_questions}
            />
          )}
          {!!jsondocument?.insight_cluster_thesis?.content?.length && (
            <ReportSection
              type="list"
              section={jsondocument?.insight_cluster_thesis}
            />
          )}
          {!!jsondocument?.br_insights?.content && (
            <ReportSection
              type="indicator"
              section={jsondocument?.br_insights}
            />
          )}
          {!!jsondocument?.transcript_insights?.content?.length && (
            <ReportSection
              type="text"
              section={jsondocument?.transcript_insights}
              defaultOpen={false}
            />
          )}
          {!!jsondocument?.mkt_pos_report_str?.content?.length && (
            <ReportSection
              type="text"
              section={jsondocument?.mkt_pos_report_str}
              defaultOpen={false}
            />
          )}
        </div>
        <section className="mt-6">
          <h2 className="sr-only">Disclaimer</h2>
          <div className="p-4 pr-10 text-xxs sm:text-xs tracking-tight leading-snug text-bam-medium-gray">
            {ReportDisclaimer}
          </div>
        </section>
      </motion.div>
      {relatedDocuments.length > 0 && (
        <div key="related-reports" className="flex flex-col w-full mt-8">
          <h2 className="font-bebas-neue text-xl text-bam-blue">
            More Reports on This Ticker
          </h2>
          <div className="grid grid-cols-1 gap-4 mt-3 sm:grid-cols-2 lg:grid-cols-3">
            {relatedDocuments.map((doc) => (
              <div key={doc.id} className="flex flex-col w-full">
                <ReportCard document={doc.attributes} reportDetailsView />
              </div>
            ))}
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ReportViewer;
